export class Lightbox {
  constructor() {
    this.overlay = document.querySelector('[data-lightbox="overlay"]');
    this.trigger = document.querySelectorAll('[data-lightbox="trigger"]');
    this.close = document.querySelector('[data-lightbox="close"]');
    this.image = document.querySelector('[data-lightbox="image"]');

    this.showOverlay = this.showOverlay.bind(this);
    this.closeOverlay = this.closeOverlay.bind(this);

    this.init();
  }

  init() {
    if (!this.overlay) return;
    this.bindEvents();
  }

  bindEvents() {
    this.trigger.forEach(el => el.addEventListener('click', this.showOverlay));
    this.close.addEventListener('click', this.closeOverlay);
    this.overlay.addEventListener('click', this.closeOverlay);
  }

  showOverlay(e) {
    console.log('hi');
    let imageSource = e.currentTarget.querySelector('img').getAttribute('src');

    if (imageSource && imageSource !== '#') {
      e.preventDefault();

      this.image.setAttribute('src', imageSource);
      setTimeout(() => this.overlay.classList.add('is-visible'), 100);
    }
  }

  closeOverlay(e) {
    if (e.target === this.overlay || e.target === this.close) {
      this.overlay.classList.remove('is-visible');
    }
  }
}

export default Lightbox;