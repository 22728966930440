export default class Menu {
  constructor() {
    this.DOM = {};
    this.DOM.menu = document.querySelector('.menu');

    this.toggleMenu = this.toggleMenu.bind(this);
    this.init();
  }

  init() {
    if (!this.DOM.menu) return;
    setTimeout(() => this.DOM.menu.style.display = 'block', 100);
    this.getChildren();
    this.bindEvents();
  }

  getChildren() {
    this.DOM.trigger = document.querySelector('.header__toggle');
  }

  bindEvents() {
    this.DOM.trigger.addEventListener('click', this.toggleMenu);
  }

  toggleMenu() {
    this.DOM.menu.classList.toggle('is-active');
    this.DOM.trigger.classList.toggle('is-active');
  }
}