export default class AccessGuide {
  constructor(el) {
    this.DOM = { el: el };
    this.state = {};
    this.state.winWidth = window.innerWidth;
    this.state.isOpen = false;

    this.toggleTextbox = this.toggleTextbox.bind(this);
    this.showTextbox = this.showTextbox.bind(this);
    this.hideTextbox = this.hideTextbox.bind(this);

    this.init();
  }

  init() {
    if (!this.DOM.el || this.state.winWidth > 992) return;
    this.getChildren();
    this.getHeight();
    this.bindEvents();
  }

  bindEvents() {
    this.DOM.trigger.addEventListener('click', this.toggleTextbox);
  }

  getChildren() {
    this.DOM.trigger = this.DOM.el.querySelector('.access__title');
    this.DOM.textbox = this.DOM.el.querySelector('.access__textbox');
  }

  getHeight() {
    this.state.itemHeight = this.DOM.textbox.scrollHeight;
  }

  toggleTextbox() {
    this.state.isOpen ? this.hideTextbox() : this.showTextbox();
  }

  showTextbox() {
    this.state.isOpen = true;
    this.DOM.trigger.classList.add('is-active');
    this.DOM.textbox.style.maxHeight = `${this.state.itemHeight}px`;
  }

  hideTextbox() {
    this.state.isOpen = false;
    this.DOM.trigger.classList.remove('is-active');
    this.DOM.textbox.style.maxHeight = '0px';
  }
}
