import '../styles/main.scss';
import smoothscroll from 'smoothscroll-polyfill';
import Util from './modules/Utilities';
import Sliders from './modules/Sliders';
import Menu from './modules/Menu';
import AccessGuide from './modules/AccessGuide';
import Lightbox from './modules/Lightbox';

document.addEventListener('DOMContentLoaded', () => {
  smoothscroll.polyfill();
  Util.forEachPolyfill();
  Util.anchorsInit();

  Sliders.heroSlider();
  Sliders.tourismSlider();
  Sliders.roomSlider();

  new Menu();
  new Lightbox();
});

window.onload = () => {
  const accessList = document.querySelectorAll('.access__item');
  accessList.forEach(item => new AccessGuide(item));
}