import Swiper from '../vendors/swiper.min.js';

export default class Sliders {
  static heroSlider() {
    const selector = document.querySelector('.hero__slider');
    const options = {
      slidesPerView: 1.3,
      spaceBetween: 30,
      centeredSlides: true,
      loop: true,
      autoplay: {
        delay: 3000,
        disableOnInteraction: false
      },
      loopedSlides: 3,
      speed: 1000,
      breakpoints: {
        768: {
          slidesPerView: 1,
          spaceBetween: 0
        }
      }
    }

    return new Swiper(selector, options);
  }

  static tourismSlider() {
    const selector = document.querySelector('.tourism__wrap');
    const options = {
      slidesPerView: 3,
      spaceBetween: 20,
      loop: true,
      pagination: {
        el: '.tourism__pagination',
        clickable: true,
        type: 'bullets'
      },
      autoplay: {
        delay: 3000,
        disableOnInteraction: false
      },
      navigation: {
        nextEl: '.tourism__next',
        prevEl: '.tourism__prev',
      },
      breakpoints: {
        768: {
          slidesPerView: 2
        },
        576: {
          slidesPerView: 1
        }
      }
    }

    return new Swiper(selector, options);
  }

  static roomSlider() {
    const el = document.querySelector('.room__wrap');
    const wrapper = el.querySelector('.swiper-wrapper');
    const slides = el.querySelectorAll('.swiper-slide');
    const options = {
      slidesPerView: 1,
      autoplay: {
        delay: 3000,
        disableOnInteraction: false
      },
      pagination: {
        el: '.room__pagination',
        clickable: true,
        type: 'bullets'
      },
      navigation: {
        nextEl: '.room__next',
        prevEl: '.room__prev',
      },
      loop: true
    };
    let slider;
    let isInitialized = false;

    const init = () => {
      if (window.innerWidth < 768 && !isInitialized) {
        isInitialized = true;
        slider = new Swiper(el, options);
      }
      else if (window.innerWidth > 768 && isInitialized) {
        isInitialized = false;
        slider.destroy();
        wrapper.removeAttribute('style');
        slides.forEach(slide => slide.removeAttribute('style'));
      }
    }

    window.addEventListener('resize', init);
    init();
  }
}